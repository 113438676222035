import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { findRouteData } from 'src/lib/router/find-route-data';
import { getRouteParamFromRoot } from 'src/lib/router/find-route-param';
import { ApiClient } from 'src/shared-services/api-client.generated.service';

@Injectable()
export class ClientContextService {
  public get value(): ApiClient.ClientContextDto {
    return getClientContext(this._router.routerState.root.snapshot);
  }

  public get valueChanges(): Observable<ApiClient.ClientContextDto> {
    return this._router.events.pipe(map(() => this.value));
  }

  constructor(private _router: Router) {}
}

/**
 * Helper function to traverse the Router tree to find the client context
 * @param {ActivatedRouteSnapshot} route - Snapshot of the currently activated route
 * @returns {ApiClient.ClientContextDto} - Current client context
 * @throws Will throw an error if the client context is not found in the tree
 */
export function getClientContext(
  route: ActivatedRouteSnapshot,
): ApiClient.ClientContextDto {
  const clientContext = findClientContext(route);
  if (clientContext == null) {
    throw new Error('Unable to find client context in route tree');
  }
  return clientContext;
}

export function findClientContext(
  route: ActivatedRouteSnapshot,
): ApiClient.ClientContextDto | undefined {
  return findRouteData<ApiClient.ClientContextDto>(route, 'clientContext');
}

export async function findOrFetchClientContext(
  route: ActivatedRouteSnapshot,
  clientContextService: ApiClient.ClientContextService,
): Promise<ApiClient.ClientContextDto> {
  let clientContext = findClientContext(route);
  if (clientContext == null) {
    const portalId = getRouteParamFromRoot(route, 'portalId');
    const ccr = await clientContextService
      .getClientContextByPortalId(portalId)
      .toPromise();
    clientContext = ccr.result;
    route.data = {
      ...route.data,
      clientContext,
    };
  }
  return clientContext;
}
