import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GaCountrySelectModule } from '@ga/ga-uikit/country-select';
import { GaGeographyModule } from '@ga/ga-uikit/geography';
import { GaHeaderModule } from '@ga/ga-uikit/header';
import { GaIconModule } from '@ga/ga-uikit/icon';
import { GaSideNavigationModule } from '@ga/ga-uikit/side-navigation';
import { GaTileModule } from '@ga/ga-uikit/tile';
import { GainPercentPipe } from 'src/lib/percent/gain-percent.pipe';
import { DateOnlyPipe } from 'src/lib/pipes/date-only-pipe';
import { GeographyModule } from 'src/shared-modules/geography/geography.module';
import { MoneyModule } from 'src/shared-modules/money/money.module';
import { FormatDisplayPipe } from '../../lib/pipes/format-display.pipe';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { ClickOutsideDirective } from '../click-outside/click-outside.directive';
import { ClickOutsideModule } from '../click-outside/click-outside.module';
import { DragDropModule } from '../drag-drop/drag-drop.module';
import { EmphasizeCharactersModule } from '../emphasize-characters/emphasize-characters.module';
import { GaClipboardModule } from '../ga-clipboard/ga-clipboard.module';
import { GaConfirmDialogModule } from '../ga-confirm-dialog/ga-confirm-dialog.module';
import { GaPageEditingModule } from '../ga-page-editing/ga-page-editing.module';
import { GaPageModule } from '../ga-page/page.module';
import { GaProcessMonitorModule } from '../ga-process-monitor/process-monitor.module';
import { GaRegionSelectModule } from '../ga-region-select/region-select.module';
import { GaRequiredAsteriskComponent } from '../ga-required-asterisk/ga-required-asterisk.component';
import { GaTableCellInputComponent } from '../ga-table-cell-input/ga-table-cell-input.component';
import { GaTableDeleteModule } from '../ga-table-delete/ga-table-delete.module';
import { GaHelpIconComponent } from '../help-icon/help-icon.component';
import { InformationDialogModule } from '../information-dialog/information-dialog.module';
import { GaInputFormFieldComponent } from '../input-form-field/input-form-field.component';
import { LayoutNavigationModule } from '../layout-navigation/layout-navigation.module';
import { LimitDecimalsModule } from '../limit-decimals/limit-decimals.module';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { RunCalculationEmployeeInformationModule } from '../run-calculation-employee-information-header/run-calculation-employee-information.module';
import { GaGAINSelectFormFieldModule } from '../select-form-field/select-form-field.module';
import { CamelCaseToSentencePipe } from '../text-format/camelCase-toSentence.pipe';
import { UntypedEnumDisplayPipe } from 'src/lib/enums/untyped-enum-display.pipe';
import { BatchStatusDisplayPipe } from 'src/lib/enums/typed-enum-display-pipes/batch-status-display.pipe';
import { PlanTypeDisplayPipe } from 'src/lib/enums/typed-enum-display-pipes/plan-type-display.pipe';
import { CalculationStatusDisplayPipe } from 'src/lib/enums/typed-enum-display-pipes/calculation-status-display.pipe';
import { TransactionStatusDisplayPipe } from 'src/lib/enums/typed-enum-display-pipes/transaction-status-display.pipe';

@NgModule({
  declarations: [CamelCaseToSentencePipe, DateOnlyPipe, FormatDisplayPipe],
  imports: [
    UntypedEnumDisplayPipe,
    BatchStatusDisplayPipe,
    PlanTypeDisplayPipe,
    CalculationStatusDisplayPipe,
    TransactionStatusDisplayPipe,
    ClickOutsideModule,
    CommonModule,
    DragDropModule,
    EmphasizeCharactersModule,
    FormsModule,
    GaClipboardModule,
    GaConfirmDialogModule,
    GaCountrySelectModule,
    GaGAINSelectFormFieldModule,
    GaGeographyModule,
    GaHeaderModule,
    GaHelpIconComponent,
    GaIconModule,
    GainPercentPipe,
    GaInputFormFieldComponent,
    GaPageEditingModule,
    GaPageModule,
    GaProcessMonitorModule,
    GaRegionSelectModule,
    GaRequiredAsteriskComponent,
    GaSideNavigationModule,
    GaTableCellInputComponent,
    GaTableDeleteModule,
    GaTileModule,
    GeographyModule,
    InformationDialogModule,
    LayoutNavigationModule,
    LimitDecimalsModule,
    MaterialUIModule,
    MatTooltipModule,
    MoneyModule,
    ReactiveFormsModule,
    RunCalculationEmployeeInformationModule,
  ],
  exports: [
    BreadcrumbsModule,
    CamelCaseToSentencePipe,
    ClickOutsideDirective,
    DateOnlyPipe,
    DragDropModule,
    EmphasizeCharactersModule,
    FormatDisplayPipe,
    FormsModule,
    GaClipboardModule,
    GaConfirmDialogModule,
    GaCountrySelectModule,
    GaGAINSelectFormFieldModule,
    GaGeographyModule,
    GaHeaderModule,
    GaHelpIconComponent,
    GaIconModule,
    GainPercentPipe,
    GaInputFormFieldComponent,
    GaPageEditingModule,
    GaPageModule,
    GaProcessMonitorModule,
    GaRegionSelectModule,
    GaRequiredAsteriskComponent,
    GaSideNavigationModule,
    GaTableCellInputComponent,
    GaTableDeleteModule,
    GaTileModule,
    GeographyModule,
    InformationDialogModule,
    LayoutNavigationModule,
    LimitDecimalsModule,
    MaterialUIModule,
    MatTooltipModule,
    MoneyModule,
    ReactiveFormsModule,
    RunCalculationEmployeeInformationModule,
    UntypedEnumDisplayPipe,
    BatchStatusDisplayPipe,
    PlanTypeDisplayPipe,
    CalculationStatusDisplayPipe,
    TransactionStatusDisplayPipe,
  ],
  providers: [
    FormatDisplayPipe,
    CalculationStatusDisplayPipe,
    PlanTypeDisplayPipe,
    TransactionStatusDisplayPipe,
  ],
})
export class SharedEssentialsModule {}
