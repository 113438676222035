import { ActivatedRouteSnapshot } from '@angular/router';

export function findRouteParamFromRoot(
  route: ActivatedRouteSnapshot,
  param: string,
) {
  let r = route.root;
  while (r.firstChild != null) {
    const v = r.params[param];
    if (v != null) {
      return v;
    }
    r = r.firstChild;
  }
  return undefined;
}

export function getRouteParamFromRoot(
  route: ActivatedRouteSnapshot,
  param: string,
) {
  const v = findRouteParamFromRoot(route, param);
  if (v == null) {
    throw new Error(`Cannot find route param '${param}'`);
  }
  return v;
}
