import { Injectable } from '@angular/core';
import { CountryRegionDataService } from 'src/shared-modules/country-region-data/country-region-data.service';

@Injectable({ providedIn: 'root' })
export class RegionNameService {
  constructor(private _countryRegion: CountryRegionDataService) {}

  findRegionName({
    countryCode,
    regionCode,
  }: {
    countryCode: string[] | string;
    regionCode: string;
  }): string | undefined {
    const regions =
      this._countryRegion.getSupportedRegionsByCountryCode(countryCode);

    if (regions != null) {
      const region = regions.find((r) => r.code === regionCode);

      return region?.name;
    }

    return undefined;
  }
}
