import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Params, RouterModule } from '@angular/router';

@Component({
  selector: 'app-page-back-link',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
  templateUrl: './page-back-link.component.html',
  styleUrl: './page-back-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBackLinkComponent {
  @Input() backLinkPageTitle = '';
  @Input() path?: string | undefined;
  @Input() queryParams: Params | null | undefined = undefined;
}
