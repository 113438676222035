<div class="session-timeout-dialog">
  <br />
  <h1 mat-dialog-title class="title">Your session has timed out!</h1>
  <div mat-dialog-content class="instructions">
    <div>
      You need to re-authenticate to continue working:
      <br />
      <div style="padding-left: 25px">
        <ol>
          <li>Click "Log In" (a new tab will open)</li>
          <li>Login and re-navigate to GAIN</li>
          <li>
            The new tab will close and you will be able to resume your work
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button color="secondary" (click)="this.logoutUser()">
      Log Out
    </button>
    <button mat-button color="primary" (click)="this.reauthenticate()">
      Log In <mat-icon>open_in_new</mat-icon>
    </button>
  </div>
</div>
