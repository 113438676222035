import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaCopyTextComponent } from './ga-copy-text/ga-copy-text.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [GaCopyTextComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    MatSnackBarModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [GaCopyTextComponent],
})
export class GaClipboardModule {}
