import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { GaGeographyModule } from '@ga/ga-uikit/geography';
import { GaRegionSelectDialogComponent } from './region-select-dialog/region-select-dialog.component';

@NgModule({
  declarations: [GaRegionSelectDialogComponent],
  imports: [
    CommonModule,
    GaGeographyModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [GaRegionSelectDialogComponent],
})
export class GaRegionSelectModule {}
