import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GaBreadcrumbsModule } from '@ga/ga-uikit/breadcrumbs';
import { AppBreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TopNavBreadcrumbsComponent } from 'src/shared-modules/breadcrumbs/topnav-breadcrumbs/top-nav-breadcrumbs.component';

@NgModule({
  declarations: [AppBreadcrumbsComponent, TopNavBreadcrumbsComponent],
  imports: [CommonModule, GaBreadcrumbsModule],
  exports: [AppBreadcrumbsComponent],
})
export class BreadcrumbsModule {}
