import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GaSideNavigationModule } from '@ga/ga-uikit/side-navigation';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { MockPageContentComponent } from './mock-page-content/mock-page-content.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';

@NgModule({
  declarations: [
    MockPageContentComponent,
    SideNavigationComponent,
    TopNavigationComponent,
  ],
  imports: [
    CommonModule,
    GaSideNavigationModule,
    MaterialUIModule,
    RouterModule,
  ],
  exports: [
    MockPageContentComponent,
    SideNavigationComponent,
    TopNavigationComponent,
  ],
})
export class LayoutNavigationModule {}
