import { Pipe, PipeTransform } from '@angular/core';
import { CountryNameService } from 'src/shared-modules/geography/country-name.service';

@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {
  constructor(private _countryName: CountryNameService) {}

  transform(
    countryCode: string | null | undefined,
    includeCode: boolean = false,
  ): string | null | undefined {
    if (countryCode == null) {
      return null;
    }

    const countryName = this._countryName.findCountryName(countryCode);

    if (!countryName) {
      return countryCode;
    }

    return includeCode ? `${countryName} (${countryCode})` : countryName;
  }
}
