import { Injectable } from '@angular/core';
import { ISO_3166_COUNTRIES } from '@ga/ga-uikit/geography';
import { CountryRegionDataService } from 'src/shared-modules/country-region-data/country-region-data.service';

@Injectable({ providedIn: 'root' })
export class CountryNameService {
  constructor(private _countryData: CountryRegionDataService) {}

  findCountryName(countryCode: string): string | undefined {
    const country = this._countryData.findCountryByCode(countryCode);

    if (country != null) {
      return country.name;
    }

    const isoCountry = ISO_3166_COUNTRIES.find((c) => c.iso2 === countryCode);

    return isoCountry?.en;
  }
}
