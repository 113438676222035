import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

export type ProcessState = 'NEW' | 'PENDING' | 'SUCCESS' | 'FAILED';

export class ProcessMonitor {
  private _state$: BehaviorSubject<ProcessState>;

  constructor(initialState: ProcessState = 'NEW') {
    this._state$ = new BehaviorSubject<ProcessState>(initialState);
  }

  get state$(): Observable<ProcessState> {
    return this._state$.asObservable();
  }

  get state(): ProcessState {
    return this._state$.getValue();
  }

  set state(value: ProcessState) {
    this._state$.next(value);
  }

  get pending(): boolean {
    return this.state === 'PENDING';
  }

  get pending$(): Observable<boolean> {
    return this._isState$('PENDING');
  }

  get new(): boolean {
    return this.state === 'NEW';
  }

  get new$(): Observable<boolean> {
    return this._isState$('NEW');
  }

  get failed(): boolean {
    return this.state === 'FAILED';
  }

  get failed$(): Observable<boolean> {
    return this._isState$('FAILED');
  }

  get success(): boolean {
    return this.state === 'SUCCESS';
  }

  get success$(): Observable<boolean> {
    return this._isState$('SUCCESS');
  }

  private _isState$(state: ProcessState) {
    return this._state$.pipe(
      map((s) => s === state),
      distinctUntilChanged(),
    );
  }

  public markAsPending() {
    this.state = 'PENDING';
  }
  public markAsSuccess() {
    this.state = 'SUCCESS';
  }
  public markAsFailed() {
    this.state = 'FAILED';
  }
  public markAsNew() {
    this.state = 'NEW';
  }

  public whenPendingOrSuccess(): Observable<boolean> {
    return combineLatest([this.pending$, this.success$]).pipe(
      map((states) => states.some((s) => s)),
    );
  }

  public whenPendingOrNotValid(
    ...controls: AbstractControl[]
  ): Observable<boolean> {
    const controlsStatusChanges$ = this._getControlsStatusChanges(controls);

    return combineLatest([this.pending$, ...controlsStatusChanges$]).pipe(
      map(([pending, ...statuses]) => pending || statuses.some((s) => !s)),
    );
  }

  public whenPendingOrSuccessOrNotValid(
    ...controls: AbstractControl[]
  ): Observable<boolean> {
    const controlsStatusChanges$ = this._getControlsStatusChanges(controls);

    return combineLatest([
      this.pending$,
      this.success$,
      ...controlsStatusChanges$,
    ]).pipe(
      map(
        ([pending, success, ...statuses]) =>
          pending || success || statuses.some((s) => !s),
      ),
    );
  }

  public whenPendingOrValid(
    ...controls: AbstractControl[]
  ): Observable<boolean> {
    const controlsStatusChanges$ = this._getControlsStatusChanges(controls);

    return combineLatest([this.pending$, ...controlsStatusChanges$]).pipe(
      map((statuses) => statuses.some((s) => s)),
    );
  }

  private _getControlsStatusChanges(
    controls: AbstractControl[],
  ): Observable<boolean>[] {
    return controls.map((c) =>
      c.statusChanges.pipe(
        startWith(c.valid),
        map(() => c.valid),
      ),
    );
  }
}
