import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CountryNamePipe } from './country-name.pipe';
import { LocationFormComponent } from './location-form/location-form.component';
import { RegionNamePipe } from './region-name.pipe';

@NgModule({
  declarations: [CountryNamePipe, RegionNamePipe, LocationFormComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    TextFieldModule,
  ],
  providers: [CountryNamePipe, RegionNamePipe],
  exports: [CountryNamePipe, RegionNamePipe, LocationFormComponent],
})
export class GeographyModule {}
