import { Injectable } from '@angular/core';
import { GaAuthService } from '@ga/central-auth-angular';
import { RuntimeConfigurationService } from '../shared-modules/runtime-configuration/runtime-configuration.service';

/*
 * This class allows the bff api client to access the auth service through the api base class since you can't directly inject the auth service
 */

@Injectable({ providedIn: 'root' })
export class ApiConfig {
  constructor(
    private authService: GaAuthService,
    private runtimeConfigService: RuntimeConfigurationService,
  ) {}

  public async getAccessToken(): Promise<string> {
    const requestRoles =
      this.runtimeConfigService.get().authOptions.requestRoles || [];
    const token = await this.authService.getAccessToken({
      requestedRoles: requestRoles,
    });

    return token;
  }
}
