import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiClient } from 'src/shared-services/api-client.generated.service';
import { findOrFetchUserContext } from './user-context.service';

@Injectable({
  providedIn: 'root',
})
export class UserContextResolver
  implements Resolve<ApiClient.IGainUserContextDto>
{
  constructor(private _userContextService: ApiClient.UserContextService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return findOrFetchUserContext(route, this._userContextService);
  }
}
