import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-page-section-table-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './page-section-table-header.component.html',
  styleUrl: './page-section-table-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaPageSectionTableHeaderComponent {
  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;

  @Output()
  searchChange: EventEmitter<string> = new EventEmitter();

  isFocused!: boolean;

  @HostListener('focus', ['$event']) onFocus() {
    this.isFocused = true;
  }

  @HostListener('blur', ['$event']) onBlur() {
    this.isFocused = false;
  }

  @Input()
  searchbar: boolean = false;

  onSearchChange(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.searchChange.emit(searchValue);
  }

  @Input()
  showFilter: boolean = false;

  @Output()
  filterToggle: EventEmitter<boolean> = new EventEmitter();

  @Input()
  filterActive: boolean = false;

  toggleFilter() {
    this.filterToggle.emit(!this.filterActive);
  }
}
