import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-ga-table-cell-input',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatInputModule, MatTooltipModule],
  templateUrl: './ga-table-cell-input.component.html',
  styleUrl: './ga-table-cell-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaTableCellInputComponent {
  @Input()
  defaultValue: string = '';

  @Input()
  newValue?: string;

  @Input()
  previouslyEditedValue?: string;

  @Output()
  inputUpdated: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Input()
  toolTipText: string = 'Click to edit';

  @Input()
  percentageFormat: string = '1.0-4';

  editing: boolean = false;

  @HostBinding('class.error')
  hasError: boolean = false;

  @ViewChild('input') input!: ElementRef;

  @HostBinding('class')
  get hostClass() {
    if (this.editing === true) {
      return 'editing';
    }
    if (
      (this.previouslyEditedValue && !this.newValue) ||
      (this.newValue && this.newValue !== this.defaultValue)
    ) {
      return 'edited';
    }
    return '';
  }

  constructor() {}

  get displayValue() {
    return this.newValue ?? this.previouslyEditedValue ?? this.defaultValue;
  }

  handleInputChanged(e: any) {
    this.limitDecimalPlaces(e);
    // Check if the input is blank or not a number
    if (e.value === '' || isNaN(e.value)) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
  }

  limitDecimalPlaces(e: any) {
    const numRegex = new RegExp('^\\d*\\.?\\d{0,4}$');
    if (!numRegex.test(e.value)) {
      e.value = e.value.substring(0, e.value.length - 1);
    }
  }

  enterEditMode() {
    this.editing = true;
    setTimeout(() => {
      if (this.input) this.input.nativeElement.focus();
    }, 100);
  }

  exitEditMode() {
    this.editing = false;
  }

  revertChanges() {
    this.inputUpdated.emit(null);
    this.exitEditMode();
  }

  updateValue(value: string) {
    if (!value) return;
    this.inputUpdated.emit(value);
    this.exitEditMode();
  }
}
