import { Pipe, PipeTransform } from '@angular/core';
import { RegionNameService } from 'src/shared-modules/geography/region-name.service';

@Pipe({ name: 'regionName' })
export class RegionNamePipe implements PipeTransform {
  constructor(private _regionName: RegionNameService) {}

  transform(
    regionCode: string,
    countryCode: string[] | string | null,
    includeCode: boolean = false,
  ): string {
    if (countryCode == null) {
      return '';
    }
    const regionName = this._regionName.findRegionName({
      countryCode,
      regionCode,
    });

    if (!regionName) {
      return regionCode;
    }

    return includeCode ? `${regionName} (${regionCode})` : regionName;
  }
}
