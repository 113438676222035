import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GaRequiredAsteriskComponent } from '../ga-required-asterisk/ga-required-asterisk.component';
import { GainErrorComponent } from './error/error.component';
import { GainOptionComponent } from './option/option.component';
import { GaGainSelectFormFieldComponent } from './select-form-field.component';
import { GaSelectTriggerComponent } from './select-trigger/select-trigger.component';

@NgModule({
  declarations: [
    GainOptionComponent,
    GaGainSelectFormFieldComponent,
    GainErrorComponent,
    GaSelectTriggerComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    MatChipsModule,
    GaRequiredAsteriskComponent,
    MatProgressSpinnerModule,
  ],
  exports: [
    GainOptionComponent,
    GaGainSelectFormFieldComponent,
    GainErrorComponent,
    GaSelectTriggerComponent,
  ],
})
export class GaGAINSelectFormFieldModule {}
