import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export type PaddingWidth = 'none' | 'normal' | 'wide';

@Component({
  selector: 'app-page-section-table-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-section-table-content.component.html',
  styleUrl: './page-section-table-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaPageSectionTableContentComponent {
  @HostBinding('style.padding')
  get padding(): string {
    return `0 0.25rem 0.75rem`;
  }

  @HostBinding('class.scrollable')
  @Input()
  scrollable: boolean = true;
}
