import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageBackLinkComponent } from './page-back-link/page-back-link.component';
import { GaPageFooterActionsComponent } from './page-footer/page-footer-actions/page-footer-actions.component';
import { GaPageFooterComponent } from './page-footer/page-footer.component';
import { GaPageHeaderActionsComponent } from './page-header/page-header-actions/page-header-actions.component';
import { GaPageHeaderDescriptionComponent } from './page-header/page-header-description/page-header-description.component';
import { GaPageHeaderTitleComponent } from './page-header/page-header-title/page-header-title.component';
import { GaPageHeaderComponent } from './page-header/page-header.component';
import { GaPageSectionContentComponent } from './page-section/page-section-content/page-section-content.component';
import { GaPageSectionHeaderActionsComponent } from './page-section/page-section-header-actions/page-section-header-actions.component';
import { GaPageSectionHeaderDescriptionComponent } from './page-section/page-section-header/page-section-header-description/page-section-header-description.component';
import { GaPageSectionHeaderTitleComponent } from './page-section/page-section-header/page-section-header-title/page-section-header-title.component';
import { GaPageSectionHeaderComponent } from './page-section/page-section-header/page-section-header.component';
import { GaPageSectionTableContentComponent } from './page-section/page-section-table-content/page-section-table-content.component';
import { GaPageSectionTableHeaderComponent } from './page-section/page-section-table-header/page-section-table-header.component';
import { GaPageSectionComponent } from './page-section/page-section.component';
import { GaPageComponent } from './page.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GaPageComponent,
    GaPageHeaderComponent,
    GaPageHeaderActionsComponent,
    GaPageSectionComponent,
    GaPageSectionHeaderComponent,
    GaPageSectionHeaderActionsComponent,
    GaPageFooterComponent,
    GaPageFooterActionsComponent,
    GaPageHeaderTitleComponent,
    GaPageHeaderDescriptionComponent,
    GaPageSectionContentComponent,
    GaPageSectionHeaderTitleComponent,
    GaPageSectionHeaderDescriptionComponent,
    PageBackLinkComponent,
    GaPageSectionTableHeaderComponent,
    GaPageSectionTableContentComponent,
  ],
  exports: [
    GaPageComponent,
    GaPageHeaderComponent,
    GaPageHeaderActionsComponent,
    GaPageSectionComponent,
    GaPageSectionTableHeaderComponent,
    GaPageSectionHeaderComponent,
    GaPageSectionHeaderActionsComponent,
    GaPageFooterComponent,
    GaPageFooterActionsComponent,
    GaPageHeaderTitleComponent,
    GaPageHeaderDescriptionComponent,
    GaPageSectionContentComponent,
    GaPageSectionHeaderTitleComponent,
    GaPageSectionHeaderDescriptionComponent,
    PageBackLinkComponent,
    GaPageSectionTableContentComponent,
  ],
})
export class GaPageModule {}
