import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export type PaddingWidth = 'none' | 'normal' | 'wide';

@Component({
  selector: 'app-page-section-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-section-content.component.html',
  styleUrl: './page-section-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaPageSectionContentComponent {
  @Input()
  overridePadding!: string | undefined;

  normalPaddingInRem: number = 1.5;
  widePaddingInRem: number = 10.5;

  @Input()
  padding: PaddingWidth = 'normal';

  @HostBinding('style.padding')
  get sidePaddingInRem(): string {
    if (this.overridePadding) return this.overridePadding;
    if (this.padding === 'none') return `0 0.25rem 0.75rem`;

    if (this.padding === 'wide')
      return `0 ${this.widePaddingInRem}rem ${this.normalPaddingInRem}rem`;

    return `0 ${this.normalPaddingInRem}rem ${this.normalPaddingInRem}rem`;
  }

  // Use of marginTop here instead of paddingTop to fix scrollable table bug
  @HostBinding('style.marginTop.rem')
  @Input()
  paddingTopInRem: number = this.normalPaddingInRem;

  @HostBinding('class.scrollable')
  @Input()
  scrollable: boolean = false;
}
