import { Injectable } from '@angular/core';
import { ClientContextService } from 'src/app/clients/client-shared/client-context.service';

export interface PrecisionSettings {
  numericMinDigits: number;
  numericMaxDigits: number;
  percentageMinDigits: number;
  percentageMaxDigits: number;
}

const GAIN_DEFAULT_PRECISION_SETTINGS: PrecisionSettings = {
  numericMaxDigits: 2,
  numericMinDigits: 2,
  percentageMaxDigits: 6,
  percentageMinDigits: 6,
};

export abstract class PrecisionService {
  abstract getPrecisionSettings(): PrecisionSettings;
}

@Injectable()
export class GainDefaultPrecisionService extends PrecisionService {
  getPrecisionSettings(): PrecisionSettings {
    return GAIN_DEFAULT_PRECISION_SETTINGS;
  }
}

@Injectable()
export class ClientConfigurationPrecisionService extends PrecisionService {
  constructor(private clientContext: ClientContextService) {
    super();
  }
  getPrecisionSettings(): PrecisionSettings {
    return {
      percentageMaxDigits: this.clientContext.value.percentagePrecision,
      percentageMinDigits: this.clientContext.value.percentagePrecision,
      numericMinDigits: this.clientContext.value.numericPrecision,
      numericMaxDigits: this.clientContext.value.numericPrecision,
    };
  }
}
