import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-page-header-description',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-header-description.component.html',
  styleUrl: './page-header-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaPageHeaderDescriptionComponent {}
