import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ErrorComponent } from '@ga/ga-uikit/error';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-input-form-field',
  templateUrl: './input-form-field.component.html',
  styleUrls: ['./input-form-field.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GaInputFormFieldComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, OverlayModule, MatIconModule, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaInputFormFieldComponent
  implements
    OnInit,
    AfterContentInit,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy
{
  @Input()
  label: string = '';

  @Input()
  hideLabel: boolean = false;

  @Input()
  variant: 'regular' | 'small' = 'regular';

  @Input()
  hint?: string;

  @Input()
  hideHintError: boolean = false;

  @Input()
  @HostBinding('class.ga-input-form-field--disabled')
  disabled: boolean = false;

  @Input()
  @HostBinding('class.ga-input-form-field--static')
  static: boolean = false;

  @HostBinding('class.active')
  active: boolean = false;

  protected _value: string = '';

  @Input()
  set value(value: string) {
    this._value = value;
    this.onChange(this.value);
  }

  get value() {
    return this._value;
  }

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  @Output()
  readonly saveChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  disableWrap: boolean = false;

  @HostBinding('class') class!: string;

  ngOnInit() {
    this.class = `ga-input-form-field--${this.variant}`;
  }

  markAsTouched() {
    if (this.disabled) return;
    this.onTouched();
    this.touched = true;
    this.active = false;
    this.cd.markForCheck();
  }

  @HostBinding('attr.tabIndex')
  @Input()
  tabIndex = 0;

  private unsubscribe$ = new Subject();

  touched: boolean = false;

  protected onChange: (newValue: string) => void = () => {};
  protected onTouched: () => void = () => {};

  hasError: boolean = false;

  @ContentChildren(ErrorComponent)
  errors!: QueryList<ErrorComponent>;

  firstError!: ErrorComponent;

  subscription!: Subscription;

  private unlistener!: () => void;

  private updateFirstError() {
    this.firstError = this.errors.first;
  }

  @ViewChild('input')
  inputField!: ElementRef;

  @HostListener('click')
  openPanel() {
    if (this.disabled) return;
    this.active = true;
    setTimeout(() => {
      this.inputField.nativeElement.focus();
    }, 0);
    this.cd.markForCheck();
  }

  constructor(
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
  ) {}

  handleInputChange(value: string) {
    this.value = value;
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  ngAfterContentInit(): void {
    this.updateFirstError();
    this.subscription = this.errors.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateFirstError());
  }

  ngAfterViewInit() {
    if (this.inputField) {
      this.unlistener = this.renderer2.listen(
        this.inputField.nativeElement,
        'input',
        (event) => this.calcHeight(event),
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    if (this.unlistener) {
      this.unlistener();
    }
  }

  calcHeight(event: any) {
    this.renderer2.setStyle(event.target, 'height', 'auto');
    const scrollHeight = event.target.scrollHeight;

    // min-height + lines x line-height
    const minHeight = this.variant === 'regular' ? 36 : 24; // Font size plus extra pixels to not break line height;
    const numberOfLineBreaks = (event.target.value.match(/\n/g) || []).length;
    const newHeight = minHeight + numberOfLineBreaks * minHeight;
    this.renderer2.setStyle(
      event.target,
      'height',
      `${newHeight < scrollHeight ? `${scrollHeight}px` : `${newHeight}px`}`,
    );
  }

  handleSaveChanges($event: boolean) {
    this.saveChanges.emit($event);
  }
}
