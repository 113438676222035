import {
  RouterEvent,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { ProcessMonitor } from './process-monitor';

export function monitorNavigation(
  event: RouterEvent,
  navigation: ProcessMonitor,
) {
  if (event instanceof NavigationStart) {
    navigation.markAsPending();
  } else if (event instanceof NavigationEnd) {
    navigation.markAsSuccess();
  } else if (event instanceof NavigationCancel) {
    navigation.markAsSuccess();
  } else if (event instanceof NavigationError) {
    navigation.markAsFailed();
  }
}
