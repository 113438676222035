import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { GaAuthService } from '@ga/central-auth-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RuntimeConfigurationService } from 'src/shared-modules/runtime-configuration/runtime-configuration.service';
import { ApiClient } from 'src/shared-services/api-client.generated.service';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  loginPageUrl: string;
  portalHomeUrl: string;
  portalHelpUrl: string;
  menuItems: ApiClient.PortalAppTilesDto[] | null = null;
  _onDestroy$ = new Subject<void>();

  constructor(
    private _router: Router,
    private _authService: GaAuthService,
    private _config: RuntimeConfigurationService,
    private menuService: ApiClient.MenuService,
  ) {
    const { loginPageUrl, portalHomeUrl, portalHelpUrl } =
      this._config.get().authOptions;
    this.loginPageUrl = loginPageUrl;
    this.portalHomeUrl = portalHomeUrl;
    this.portalHelpUrl = portalHelpUrl;
  }

  async handleLogout() {
    await this._authService.logout();
    window.location.href = this.loginPageUrl;
  }

  async ngOnInit() {
    const result = await this.menuService.getPortalAppTiles().toPromise();
    this.menuItems =
      result?.portalAppsData?.tiles.filter(
        (x) => x.tileType !== 'PORTLET' || !x.portletInfo,
      ) ?? null;
  }

  menuTileClicked(item: ApiClient.PortalAppTilesDto) {
    let isTool = false;
    let id = null;

    if (!item.productId || item.productId === -1) {
      isTool = false;
      id = item.tileId;
    } else {
      isTool = true;
      id = item.productId;
    }
    if (id == null) return;
    this.menuService
      .portalTileOrToolClickedResult(
        new ApiClient.PortalTileOrToolClickedQuery({
          id: id.toString(),
          isTool,
        }),
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (result) => {
          this.menuRedirect(item);
        },

        function (error) {
          console.error(error);
        },
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  menuRedirect(item: ApiClient.PortalAppTilesDto) {
    this.triggerSamlService(item);
  }

  triggerSamlService(tile: ApiClient.PortalAppTilesDto) {
    const item =
      tile.tileType === 'TOOL_GROUP' && tile.tiles?.length === 1
        ? tile.tiles[0]
        : tile;

    this.menuService
      .postSAMLData(
        new ApiClient.PostSAMLDataQuery({
          redirectUrl: item.navUrl ?? '',
          toolId: item.tileId ?? '',
        }),
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (result) => {
          if (item.navUrl) {
            const form = document.createElement('form');
            form.action = item.navUrl;
            form.target = '_blank';
            form.method = 'POST';
            form.setAttribute('name', 'SAMLResponsesForm');
            const formInput = document.createElement('input');
            formInput.type = 'hidden';
            formInput.name = 'SAMLResponse';
            formInput.setAttribute('value', result.token);
            form.append(formInput);
            document.body.appendChild(form);
            form.submit();
          }
        },

        (error) => {
          console.error(error);
        },
      );
  }
}
