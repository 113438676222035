import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-page-section-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-section-header.component.html',
  styleUrl: './page-section-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaPageSectionHeaderComponent {
  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;
}
