import { Pipe, PipeTransform } from '@angular/core';
import { ApiClient } from 'src/shared-services/api-client.generated.service';
import {
  EnumDisplayService,
  EnumValueDisplayFormat,
} from 'src/lib/enums/enum-display.service';

@Pipe({
  name: 'transactionStatusDisplay',
  standalone: true,
})
export class TransactionStatusDisplayPipe implements PipeTransform {
  constructor(private _enumDisplay: EnumDisplayService) {}
  transform(
    value: ApiClient.TransactionStatus | null | undefined,
    format: EnumValueDisplayFormat = 'display name',
  ): string | null {
    if (value == null) {
      return null;
    }
    return this._enumDisplay.getEnumDisplay('TransactionStatus', value, format);
  }
}
