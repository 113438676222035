import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmUnsavedChangesDialogComponent } from './confirm-unsaved-changes-dialog/confirm-unsaved-changes-dialog.component';

@NgModule({
  declarations: [ConfirmUnsavedChangesDialogComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
})
export class GaPageEditingModule {}
