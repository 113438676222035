import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-page-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-footer.component.html',
  styleUrl: './page-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaPageFooterComponent {
  @HostBinding('class.full-width')
  fullWidth: boolean = true;
}
