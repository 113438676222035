import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LimitDecimalsDirective } from './limit-decimals.directive';

@NgModule({
  declarations: [LimitDecimalsDirective],
  imports: [CommonModule],
  exports: [LimitDecimalsDirective],
})
export class LimitDecimalsModule {}
