import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GaLoadingProgressBarComponent } from './ga-loading-progress-bar/ga-loading-progress-bar.component';
import { GaButtonSpinnerComponent } from './ga-button-spinner/ga-button-spinner.component';

@NgModule({
  declarations: [GaLoadingProgressBarComponent, GaButtonSpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
  exports: [GaLoadingProgressBarComponent, GaButtonSpinnerComponent],
})
export class GaProcessMonitorModule {}
