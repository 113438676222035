<!-- Need two different header paddings - with and without actions -->
<div>
  <ng-content select="app-page-section-header-title"></ng-content>
</div>
<div class="header-options">
  <div
    *ngIf="searchbar"
    (click)="searchBar.focus()"
    class="section-header-table-search-bar"
  >
    <mat-icon>search</mat-icon>
    <input
      (focus)="onFocus()"
      (blur)="onBlur()"
      [class.focused]="isFocused || (searchBar.value.length > 0 && !isFocused)"
      #searchBar
      matInput
      type="text"
      (keyup)="onSearchChange($event)"
      placeholder="Search"
    />
  </div>
  @if (showFilter) {
    <div
      (click)="toggleFilter()"
      class="section-header-table-filter"
      [class.active]="filterActive"
    >
      <mat-icon>filter_list</mat-icon>
    </div>
  }
</div>
