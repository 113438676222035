<div
  [ngClass]="{
    'content-wide': width === 'wide',
    'content-full': width === 'full',
  }"
>
  <ng-content select="app-breadcrumbs"></ng-content>
  <ng-content select="ga-breadcrumbs"></ng-content>
  <ng-content select="app-page-back-link"></ng-content>

  <div class="header-container">
    <ng-content select="app-page-header"></ng-content>
  </div>

  <ng-content></ng-content>
</div>

<!-- Page Footer - always full width-->
<ng-content select="app-page-footer" class="full-width"></ng-content>
