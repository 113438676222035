import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogModalComponent } from './confirm-dialog-modal/confirm-dialog-modal.component';

@NgModule({
  declarations: [ConfirmDialogModalComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  exports: [ConfirmDialogModalComponent],
})
export class GaConfirmDialogModule {}
