import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GaPageHeaderActionsComponent } from './page-header-actions/page-header-actions.component';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [CommonModule, GaPageHeaderActionsComponent, MatIconModule],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaPageHeaderComponent {
  @ContentChild(GaPageHeaderActionsComponent)
  pageActionComponent!: GaPageHeaderActionsComponent;
}
