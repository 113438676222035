import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConvertibleMoneyComponent } from './convertible-money/convertible-money.component';
import { CurrencyCodeNamePipe } from './currency-code-name.pipe';
import { CurrencyNamePipe } from './currency-name.pipe';
import { MoneyAmountComponent } from './money-amount/money-amount.component';
import { MoneyCurrencyCodeComponent } from './money-currency-code/money-currency-code.component';
import { MoneyFormComponent } from './money-form/money-form.component';
import { MoneyFormatterDirective } from './money-formatter.directive';
import { MoneyPipe } from './money.pipe';
import { MoneyComponent } from './money/money.component';
import { LimitDecimalsModule } from '../limit-decimals/limit-decimals.module';

@NgModule({
  declarations: [
    MoneyComponent,
    CurrencyNamePipe,
    ConvertibleMoneyComponent,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyFormComponent,
    MoneyAmountComponent,
    MoneyCurrencyCodeComponent,
  ],
  exports: [
    MoneyComponent,
    CurrencyNamePipe,
    ConvertibleMoneyComponent,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyFormComponent,
    MoneyAmountComponent,
    MoneyCurrencyCodeComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    LimitDecimalsModule,
  ],
})
export class MoneyModule {}
