import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { EmphasizeCharacters } from './emphasize-characters.pipe';

@NgModule({
  declarations: [EmphasizeCharacters],
  imports: [CommonModule],
  exports: [EmphasizeCharacters],
})
export class EmphasizeCharactersModule {}
