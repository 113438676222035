import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { InformationDialogDescriptionComponent } from './information-dialog-description/information-dialog-description.component';
import { InformationDialogTitleComponent } from './information-dialog-title/information-dialog-title.component';
import { InformationDialogComponent } from './information-dialog.component';

@NgModule({
  declarations: [
    InformationDialogComponent,
    InformationDialogTitleComponent,
    InformationDialogDescriptionComponent,
  ],
  imports: [CommonModule, MaterialUIModule],
  exports: [
    InformationDialogComponent,
    InformationDialogTitleComponent,
    InformationDialogDescriptionComponent,
  ],
})
export class InformationDialogModule {}
