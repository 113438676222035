import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDeleteTableDialogComponent } from './confirm-delete-table-dialog/confirm-delete-table-dialog.component';
import { ConfirmUncheckTableDialogComponent } from './confirm-uncheck-table-dialog/confirm-uncheck-table-dialog.component';

@NgModule({
  declarations: [
    ConfirmDeleteTableDialogComponent,
    ConfirmUncheckTableDialogComponent,
  ],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
})
export class GaTableDeleteModule {}
