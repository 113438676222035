import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RunCalculationEmployeeInformationComponent } from './run-calculation-employee-information/run-calculation-employee-information.component';

@NgModule({
  declarations: [RunCalculationEmployeeInformationComponent],
  imports: [CommonModule],
  exports: [RunCalculationEmployeeInformationComponent],
})
export class RunCalculationEmployeeInformationModule {}
