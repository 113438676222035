import { Pipe, PipeTransform } from '@angular/core';
import {
  capitalize,
  kebabCase,
  lowerCase,
  startCase,
  upperCase,
} from 'lodash-es';

type CaseType =
  | 'sentence'
  | 'initials'
  | 'kebab'
  | 'sentenceSpecialCharacters'
  | 'title'
  | 'upper'
  | 'reverseFormat';

@Pipe({
  name: 'formatDisplay',
})
export class FormatDisplayPipe implements PipeTransform {
  transform(
    value: string[] | string | null | undefined,
    caseType: CaseType = 'sentence',
  ): string {
    if (!value) {
      return '';
    }
    if (Array.isArray(value)) {
      return value.map((v) => this._formatDisplay(v, caseType)).join(', ');
    }

    return this._formatDisplay(value, caseType);
  }

  private _formatDisplay(value: string, caseType: CaseType) {
    if (
      caseType !== 'reverseFormat' &&
      (value.toLowerCase().replace(/\s/g, '') === 'restrictedstockunit' ||
        value.toLowerCase() === 'rsu')
    ) {
      return 'RSU';
    }

    switch (caseType) {
      case 'initials':
        return value.replace(/[a-z\s]/g, '');
      case 'kebab':
        return capitalize(kebabCase(value));
      case 'sentenceSpecialCharacters':
        return capitalize(value.toLowerCase());
      case 'title':
        return startCase(value);
      case 'upper':
        return upperCase(value);
      case 'reverseFormat': {
        if (value.toLowerCase() === 'rsu') {
          return 'RestrictedStockUnit';
        }
        return value.replace(/\s+([a-z])/g, (_, p1) => p1.toUpperCase());
      }
      default:
        return capitalize(lowerCase(value));
    }
  }
}
